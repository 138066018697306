header .Hotbg{
    position: relative;
    top: 10px;
    left: 20px;
    transform: translate(-20px, -10px);
    background:whitesmoke;
    height: 40px;
    border-radius: 40px;
}
.thestockcity{
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.fontfamliy{
    font-family:""
}
header .Hotbg:hover > .Hotbg-txt{
    width: 200px;
    padding: 0 9px;
}

header .Hotbg:hover > .Hotbg-btn{
    background: azure;
    color: black;
}

header .Hotbg-btn{
    color: #e84118;
    float: right;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color:whitesmoke;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s;
    color: black;
    cursor: pointer;
}

header .Hotbg-btn > i{
    font-size-adjust: 30px;
}

header .Hotbg-txt{
    border: none;
    background: none;
    outline: none;
    float: left;
    padding: 0;
    color: azure;
    font-size: 16px;
    transition: 0.4s;
    line-height: 40px;
    width: 0px;
    font-weight: bold;
}
@media screen and (min-width:768px) and (max-width:1023px) {
    header .Hotbg:hover > .Hotbg-txt{
        width: 100px;
    }
}
@media screen and (max-width:767px) {
    header .Hotbg:hover > .Hotbg-txt{
        width: 50px;
    }
}